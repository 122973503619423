import React from 'react';
import style from './product-list.mod.scss';
import Container from '../Layout/SharedStyles/Container';
import ProductCard from './ProductCard';

const ProductList = ({ products }) => {
  return (
    <Container width="medium" pt={2} pb={2}>
      <div className={style.list}>
        <div className={style.list__grid}>
          {products.nodes.map((product) => (
            <ProductCard product={product} />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default ProductList;
