import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from '../Button/Button';
import style from './product-card.mod.scss';
import IconSwirlSecondary from '../Layout/Icons/IconSwirlSecondary';
import IconLogoVortex from '../Layout/Icons/IconLogoVortex';

const ProductList = ({ product }) => {
  const filteredFeatures = product.productFeatureList.filter(
    (item) => item.showOnProductCard
  );

  const contactPage = useStaticQuery(graphql`
    {
      datoCmsContactPage {
        originalId
      }
    }
  `);

  const getIcon = () => {
    if (product.brand === 'Vortex') {
      return <IconLogoVortex />;
    } else {
      return <IconSwirlSecondary />;
    }
  };

  return (
    <div className={style.card}>
      <div className={style.card__image}>
        <GatsbyImage
          image={product.featureImage.gatsbyImageData}
          alt={product.featureImage.alt || ''}
        />
        <span className={style.card__swirl}>
          {getIcon()}
        </span>
      </div>
      <div className={style.card__content}>
        <h3 className={style.card__title}>{product.title}</h3>
        <p className={style.card__description}>{product.description}</p>
      </div>
      <div className={style.card__list}>
        {filteredFeatures.map(({ specReference, value }) => (
          <ul className={style.card__item}>
            <li className={style.card__item__heading}>
              {specReference.titleTranslation
                ? specReference.titleTranslation
                : specReference.title}
            </li>
            <li>{value}</li>
          </ul>
        ))}
      </div>
      <div className={style.card__buttons}>
        <Button size={'small'} recordId={product.id}>
          {'more'}
        </Button>
        <Button
          size={'small'}
          recordId={contactPage.datoCmsContactPage.originalId}
        >
          {'contact a spa expert'}
        </Button>
      </div>
    </div>
  );
};

export default ProductList;
