import React from 'react';

const IconSwirlSecondary = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 69.5 69.5"
      width={90}
      height={70}
    >
      <g id="Layer_2" data-name="Layer 2" transform="translate(0 .03)">
        <g
          id="Layer_1"
          fill="#3095b4"
          data-name="Layer 1"
          transform="translate(0 -.03)"
        >
          <path
            id="Path_157"
            d="M145.84 165.25c2.12.33 4.13.69 6.14.96 5.94.8 11.65-.44 17.33-2a5.32 5.32 0 0 1 2.21-.27 2.22 2.22 0 0 1 2.08 2.31 2.46 2.46 0 0 1-2.34 2.4c-7.63 1.42-18.06.76-25.42-3.4Z"
            data-name="Path 157"
            transform="translate(-106.14 -119.28)"
          />
          <path
            id="Path_158"
            d="M35.27 75.55c-2.13-.33-4.13-.69-6.14-.96-5.94-.8-11.65.44-17.33 2a5.32 5.32 0 0 1-2.22.27 2.22 2.22 0 0 1-2.07-2.31 2.46 2.46 0 0 1 2.34-2.4c7.63-1.42 18.06-.76 25.42 3.4Z"
            data-name="Path 158"
            transform="translate(-5.47 -52)"
          />
          <path
            id="Path_159"
            d="M75.55 145.84c-.33 2.12-.69 4.13-.96 6.14-.8 5.94.44 11.65 2 17.33a5.32 5.32 0 0 1 .27 2.21 2.22 2.22 0 0 1-2.31 2.08 2.46 2.46 0 0 1-2.4-2.34c-1.42-7.63-.76-18.06 3.4-25.42Z"
            data-name="Path 159"
            transform="translate(-52.02 -106.14)"
          />
          <path
            id="Path_160"
            d="M165.25 35.27c.33-2.13.69-4.13.96-6.14.8-5.94-.44-11.65-2-17.33a5.32 5.32 0 0 1-.27-2.22 2.22 2.22 0 0 1 2.31-2.07 2.46 2.46 0 0 1 2.4 2.34c1.42 7.63.76 18.06-3.4 25.42Z"
            data-name="Path 160"
            transform="translate(-119.28 -5.46)"
          />
          <path
            id="Path_161"
            d="M172.43 68.63c1.36-1.67 2.66-3.23 3.9-4.84 3.66-4.75 5.44-10.3 6.93-16a5.4 5.4 0 0 1 .87-2.06 2.22 2.22 0 0 1 3.05-.64 2.46 2.46 0 0 1 .9 3.23c-2.58 7.31-8.36 16.02-15.65 20.3Z"
            data-name="Path 161"
            transform="translate(-125.5 -32.55)"
          />
          <path
            id="Path_162"
            d="M40.36 122.81c-1.36 1.67-2.66 3.23-3.9 4.84-3.66 4.74-5.44 10.3-6.93 16a5.4 5.4 0 0 1-.87 2.06 2.22 2.22 0 0 1-3.04.64 2.46 2.46 0 0 1-.91-3.23c2.58-7.32 8.37-16.03 15.65-20.31Z"
            data-name="Path 162"
            transform="translate(-17.8 -89.38)"
          />
          <path
            id="Path_163"
            d="M122.81 172.43c1.67 1.36 3.23 2.66 4.84 3.9 4.74 3.66 10.3 5.44 16 6.93a5.4 5.4 0 0 1 2.06.87 2.22 2.22 0 0 1 .64 3.05 2.46 2.46 0 0 1-3.23.9c-7.32-2.58-16.03-8.36-20.31-15.65Z"
            data-name="Path 163"
            transform="translate(-89.38 -125.49)"
          />
          <path
            id="Path_164"
            d="M68.62 40.36c-1.67-1.36-3.23-2.66-4.84-3.9-4.74-3.66-10.3-5.44-16-6.93a5.4 5.4 0 0 1-2.06-.87 2.23 2.23 0 0 1-.65-3.04 2.46 2.46 0 0 1 3.23-.91c7.33 2.58 16.04 8.37 20.32 15.65Z"
            data-name="Path 164"
            transform="translate(-32.55 -17.79)"
          />
          <path
            id="Path_165"
            d="M164 116.27c2-.77 3.92-1.47 5.8-2.24 5.54-2.28 9.87-6.2 14-10.4a5.54 5.54 0 0 1 1.78-1.36 2.22 2.22 0 0 1 2.96.97 2.48 2.48 0 0 1-.82 3.27c-5.9 5.04-15.27 9.69-23.72 9.76Z"
            data-name="Path 165"
            transform="translate(-119.36 -74.28)"
          />
          <path
            id="Path_166"
            d="M24.86 101.08c-2 .77-3.92 1.46-5.8 2.24-5.54 2.28-9.87 6.2-14 10.4a5.5 5.5 0 0 1-1.78 1.34 2.22 2.22 0 0 1-2.96-.97 2.46 2.46 0 0 1 .82-3.25c5.9-5.04 15.27-9.7 23.72-9.76Z"
            data-name="Path 166"
            transform="translate(0 -73.56)"
          />
          <path
            id="Path_167"
            d="M101.08 164c.77 2 1.46 3.92 2.24 5.8 2.28 5.54 6.2 9.87 10.4 14a5.5 5.5 0 0 1 1.34 1.78 2.22 2.22 0 0 1-.97 2.96 2.46 2.46 0 0 1-3.25-.82c-5.04-5.9-9.7-15.27-9.76-23.72Z"
            data-name="Path 167"
            transform="translate(-73.57 -119.35)"
          />
          <path
            id="Path_168"
            d="M116.25 24.84c-.77-2-1.47-3.92-2.25-5.8-2.28-5.55-6.2-9.88-10.4-14a5.54 5.54 0 0 1-1.36-1.8A2.22 2.22 0 0 1 103.2.3a2.48 2.48 0 0 1 3.27.82c5.05 5.91 9.7 15.28 9.77 23.73Z"
            data-name="Path 168"
            transform="translate(-74.26 .03)"
          />
          <path
            id="Path_169"
            d="M143.82 151.53c1.63.14 3.19.3 4.73.4 4.58.27 8.86-.99 13.1-2.5a4.15 4.15 0 0 1 1.68-.34 1.7 1.7 0 0 1 1.71 1.66 1.88 1.88 0 0 1-1.65 1.96c-5.74 1.5-13.73 1.59-19.57-1.18Z"
            data-name="Path 169"
            transform="translate(-104.67 -108.5)"
          />
          <path
            id="Path_170"
            d="M54.76 91.35c-1.64-.14-3.19-.3-4.74-.4-4.57-.27-8.86.99-13.1 2.5a4.15 4.15 0 0 1-1.67.33 1.7 1.7 0 0 1-1.72-1.65 1.88 1.88 0 0 1 1.66-1.96c5.74-1.5 13.73-1.6 19.57 1.18Z"
            data-name="Path 170"
            transform="translate(-24.4 -64.86)"
          />
          <path
            id="Path_171"
            d="M91.35 143.82c-.14 1.63-.3 3.19-.4 4.73-.27 4.58 1 8.86 2.5 13.1a4.15 4.15 0 0 1 .34 1.68 1.7 1.7 0 0 1-1.65 1.71 1.88 1.88 0 0 1-1.97-1.65c-1.5-5.74-1.59-13.73 1.18-19.57Z"
            data-name="Path 171"
            transform="translate(-64.87 -104.67)"
          />
          <path
            id="Path_172"
            d="M151.53 54.76c.14-1.64.3-3.19.4-4.74.27-4.57-.99-8.86-2.5-13.1a4.15 4.15 0 0 1-.34-1.67 1.7 1.7 0 0 1 1.66-1.72 1.88 1.88 0 0 1 1.96 1.66c1.5 5.74 1.59 13.73-1.18 19.57Z"
            data-name="Path 172"
            transform="translate(-108.52 -24.4)"
          />
          <path
            id="Path_173"
            d="M162.06 78.13c.94-1.36 1.85-2.61 2.7-3.9 2.53-3.82 3.58-8.18 4.4-12.6a4.16 4.16 0 0 1 .54-1.62 1.7 1.7 0 0 1 2.28-.65 1.88 1.88 0 0 1 .88 2.41c-1.56 5.72-5.48 12.68-10.8 16.36Z"
            data-name="Path 173"
            transform="translate(-117.95 -43.04)"
          />
          <path
            id="Path_174"
            d="M64.05 126.46c-.94 1.36-1.85 2.6-2.7 3.9-2.53 3.83-3.59 8.17-4.4 12.6a4.12 4.12 0 0 1-.54 1.61 1.7 1.7 0 0 1-2.29.66 1.88 1.88 0 0 1-.87-2.41c1.56-5.72 5.48-12.67 10.8-16.36Z"
            data-name="Path 174"
            transform="translate(-38.66 -92.03)"
          />
          <path
            id="Path_175"
            d="M126.46 162.06c1.36.94 2.6 1.85 3.9 2.7 3.83 2.53 8.17 3.58 12.6 4.4a4.16 4.16 0 0 1 1.61.54 1.7 1.7 0 0 1 .66 2.28 1.88 1.88 0 0 1-2.41.88c-5.72-1.56-12.67-5.48-16.36-10.8Z"
            data-name="Path 175"
            transform="translate(-92.04 -117.94)"
          />
          <path
            id="Path_176"
            d="M78.13 64.05c-1.36-.94-2.61-1.85-3.9-2.7-3.82-2.53-8.18-3.59-12.6-4.4a4.12 4.12 0 0 1-1.62-.54 1.7 1.7 0 0 1-.65-2.29 1.88 1.88 0 0 1 2.41-.87c5.72 1.56 12.68 5.48 16.36 10.8Z"
            data-name="Path 176"
            transform="translate(-43.05 -38.65)"
          />
          <path
            id="Path_177"
            d="M156.85 113.58c1.49-.7 2.9-1.33 4.3-2.03 4.1-2.05 7.18-5.28 10.1-8.7a4.14 4.14 0 0 1 1.28-1.13 1.7 1.7 0 0 1 2.31.57 1.88 1.88 0 0 1-.45 2.53c-4.22 4.16-11.1 8.24-17.54 8.76Z"
            data-name="Path 177"
            transform="translate(-114.16 -73.86)"
          />
          <path
            id="Path_178"
            d="M49.57 109.43c-1.5.7-2.91 1.33-4.3 2.03-4.1 2.05-7.18 5.28-10.1 8.7a4.14 4.14 0 0 1-1.29 1.13 1.7 1.7 0 0 1-2.3-.58 1.88 1.88 0 0 1 .44-2.52c4.23-4.17 11.1-8.24 17.55-8.76Z"
            data-name="Path 178"
            transform="translate(-22.76 -79.64)"
          />
          <path
            id="Path_179"
            d="M109.43 156.85c.7 1.49 1.33 2.9 2.03 4.3 2.05 4.1 5.28 7.18 8.7 10.1a4.14 4.14 0 0 1 1.14 1.28 1.7 1.7 0 0 1-.58 2.31 1.88 1.88 0 0 1-2.53-.45c-4.17-4.22-8.24-11.1-8.76-17.54Z"
            data-name="Path 179"
            transform="translate(-79.64 -114.15)"
          />
          <path
            id="Path_180"
            d="M113.58 49.57c-.7-1.5-1.33-2.91-2.03-4.3-2.05-4.1-5.28-7.18-8.7-10.1a4.14 4.14 0 0 1-1.13-1.29 1.7 1.7 0 0 1 .57-2.3 1.88 1.88 0 0 1 2.53.44c4.16 4.23 8.24 11.1 8.76 17.55Z"
            data-name="Path 180"
            transform="translate(-73.87 -22.75)"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconSwirlSecondary;
