import React from 'react';

const IconLogo = ({ height = 40, width = 110 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Group 12284"
      viewBox="0 0 239.92 76.98"
      height={height}
      width={width}
    >
      <defs>
        <clipPath id="a">
          <path
            fill="none"
            d="M0 0h239.92v76.98H0z"
            data-name="Rectangle 3026"
          />
        </clipPath>
      </defs>
      <g fill="#161615" data-name="Group 12283">
        <path
          d="M62.7 5.24a2.98 2.98 0 1 1-2.97-2.98 2.98 2.98 0 0 1 2.98 2.98"
          data-name="Path 14893"
        />
        <path
          d="M51.5 5.24A5.24 5.24 0 1 1 46.25 0a5.24 5.24 0 0 1 5.23 5.24"
          data-name="Path 14894"
        />
        <path
          d="M36.1 5.24a3.31 3.31 0 1 1-3.31-3.32 3.31 3.31 0 0 1 3.3 3.32"
          data-name="Path 14895"
        />
        <path
          d="M21.64 5.24a2.32 2.32 0 1 1-2.32-2.33 2.32 2.32 0 0 1 2.32 2.33"
          data-name="Path 14896"
        />
        <path
          d="M75.85 18.7a2.65 2.65 0 1 1-2.65-2.65 2.65 2.65 0 0 1 2.65 2.66"
          data-name="Path 14897"
        />
        <path
          d="M64.83 18.7a5.1 5.1 0 1 1-5.1-5.09 5.1 5.1 0 0 1 5.1 5.1"
          data-name="Path 14898"
        />
        <path
          d="M49.44 18.7a3.18 3.18 0 1 1-3.18-3.18 3.18 3.18 0 0 1 3.18 3.19"
          data-name="Path 14899"
        />
        <path
          d="M35.97 18.7a3.19 3.19 0 1 1-3.18-3.18 3.19 3.19 0 0 1 3.18 3.19"
          data-name="Path 14900"
        />
        <path
          d="M24.3 18.7a4.99 4.99 0 1 1-4.98-4.98 4.99 4.99 0 0 1 4.98 4.99"
          data-name="Path 14901"
        />
        <path
          d="M8.78 18.7a2.93 2.93 0 1 1-2.93-2.92 2.93 2.93 0 0 1 2.93 2.93"
          data-name="Path 14902"
        />
        <path
          d="M76.56 32.18a3.36 3.36 0 1 1-3.36-3.36 3.36 3.36 0 0 1 3.36 3.36"
          data-name="Path 14903"
        />
        <path
          d="M62.84 32.18a3.12 3.12 0 1 1-3.11-3.12 3.11 3.11 0 0 1 3.12 3.12"
          data-name="Path 14904"
        />
        <path
          d="M21.2 32.18a1.88 1.88 0 1 1-1.88-1.88 1.88 1.88 0 0 1 1.88 1.88"
          data-name="Path 14905"
        />
        <path
          d="M9.55 32.18a3.7 3.7 0 1 1-3.7-3.7 3.7 3.7 0 0 1 3.7 3.7"
          data-name="Path 14906"
        />
        <path
          d="M77.25 45.65a4.05 4.05 0 1 1-4.05-4.05 4.05 4.05 0 0 1 4.05 4.05"
          data-name="Path 14907"
        />
        <path
          d="M62.64 45.65a2.91 2.91 0 1 1-2.9-2.9 2.91 2.91 0 0 1 2.9 2.9"
          data-name="Path 14908"
        />
        <path
          d="M22.58 45.65a3.27 3.27 0 1 1-3.26-3.27 3.27 3.27 0 0 1 3.26 3.27"
          data-name="Path 14909"
        />
        <path
          d="M11.69 45.65a5.84 5.84 0 1 1-5.84-5.84 5.84 5.84 0 0 1 5.84 5.84"
          data-name="Path 14910"
        />
        <path
          d="M76.32 59.12A3.12 3.12 0 1 1 73.2 56a3.12 3.12 0 0 1 3.12 3.12"
          data-name="Path 14911"
        />
        <path
          d="M65.22 59.12a5.5 5.5 0 1 1-5.5-5.5 5.5 5.5 0 0 1 5.5 5.5"
          data-name="Path 14912"
        />
        <path
          d="M48.82 59.12a2.56 2.56 0 1 1-2.56-2.56 2.56 2.56 0 0 1 2.56 2.56"
          data-name="Path 14913"
        />
        <path
          d="M36.93 59.12a4.14 4.14 0 1 1-4.14-4.14 4.14 4.14 0 0 1 4.14 4.14"
          data-name="Path 14914"
        />
        <path
          d="M22.16 59.12a2.84 2.84 0 1 1-2.84-2.84 2.84 2.84 0 0 1 2.84 2.84"
          data-name="Path 14915"
        />
        <path
          d="M8.82 59.12a2.97 2.97 0 1 1-2.98-2.97 2.97 2.97 0 0 1 2.98 2.97"
          data-name="Path 14916"
        />
        <path
          d="M62.04 72.6a2.31 2.31 0 1 1-2.31-2.32 2.31 2.31 0 0 1 2.31 2.31"
          data-name="Path 14917"
        />
        <path
          d="M49.9 72.6a3.64 3.64 0 1 1-3.64-3.64 3.63 3.63 0 0 1 3.64 3.63"
          data-name="Path 14918"
        />
        <path
          d="M37.17 72.6a4.39 4.39 0 1 1-4.38-4.4 4.39 4.39 0 0 1 4.38 4.4"
          data-name="Path 14919"
        />
        <path
          d="M22.22 72.6a2.9 2.9 0 1 1-2.9-2.92 2.9 2.9 0 0 1 2.9 2.91"
          data-name="Path 14920"
        />
        <path
          d="m111.27 37.69 11.26-27.54h-6.48L108.6 28.8l-7.51-18.65H94.6l11.3 27.53zm23.19-20.5a11 11 0 0 0-7.86 3.08 9.86 9.86 0 0 0-3.2 7.46 10 10 0 0 0 3.2 7.5 11.63 11.63 0 0 0 15.68 0 9.9 9.9 0 0 0 3.24-7.5 9.76 9.76 0 0 0-3.24-7.46 10.98 10.98 0 0 0-7.82-3.08m-5.18 10.54a5 5 0 0 1 1.5-3.67 5.15 5.15 0 0 1 7.35 0 5 5 0 0 1 1.5 3.67 5.16 5.16 0 0 1-1.5 3.71 5.25 5.25 0 0 1-7.35 0 5.1 5.1 0 0 1-1.5-3.7m28.76 9.95v-9.72c0-3.28 1.97-5.18 5.33-5.18a10.18 10.18 0 0 1 1.98.2v-5.53a12.94 12.94 0 0 0-1.54-.11c-2.73 0-4.86 1.14-5.77 3.16v-2.73h-5.89v19.9zm20.85 0V23.1h4.39v-5.33h-4.38v-7.63h-5.73v7.63h-2.65v5.33h2.65v14.57zm8.85-9.84a9.8 9.8 0 0 0 3 7.5 11.16 11.16 0 0 0 7.9 2.8 11.53 11.53 0 0 0 8.58-3.63l-3.44-3.28a6.84 6.84 0 0 1-4.86 1.94c-3.12 0-5.18-1.54-5.3-3.91h15.05c.04-.6.08-1.23.08-1.9a9.87 9.87 0 0 0-10.22-10.1 10.74 10.74 0 0 0-7.74 2.88 9.91 9.91 0 0 0-3.04 7.54zm5.97-2.41c.16-1.86 2.25-3.32 4.82-3.32 2.37 0 4.3 1.47 4.3 3.32zm24.45 12.25 4.46-6.2 4.47 6.2h6.35l-7.54-10.47 6.83-9.44h-6.4l-3.71 5.25-3.71-5.25h-6.4l6.83 9.44-7.54 10.47z"
          data-name="Path 14921"
        />
        <path
          d="M96.2 64.43c1.4 2.7 4.31 4.58 8.5 4.58 5.08 0 7.7-2.5 7.7-6.33 0-2.98-1.52-4.73-5.3-6.16-.5-.2-1.1-.41-1.78-.65l-1.48-.5a12.92 12.92 0 0 1-.92-.45 1.5 1.5 0 0 1-.8-1.49c0-.98.98-1.7 2.5-1.7a4.43 4.43 0 0 1 3.71 1.61l3.06-2.32a7.94 7.94 0 0 0-6.9-3.39 7.6 7.6 0 0 0-4.9 1.6 5.1 5.1 0 0 0-2 4.26c0 3.12 1.79 4.82 5.03 6 .6.24 1.78.63 2.29.84a7.05 7.05 0 0 1 1.78.77 1.99 1.99 0 0 1 1.13 1.72c0 1.1-.98 2.05-3.09 2.05a5.64 5.64 0 0 1-5.2-2.73zm25.6 9.99v-7.7c.44 1.3 2.2 2.23 4.46 2.23a6.98 6.98 0 0 0 5.29-2.23 7.77 7.77 0 0 0 2.14-5.53 7.65 7.65 0 0 0-2.14-5.5 6.9 6.9 0 0 0-5.3-2.26c-2.25 0-4 .92-4.45 2.23V53.7h-4.43v20.72zm3.65-9.48a3.96 3.96 0 0 1-2.79-1.07 3.67 3.67 0 0 1-1.13-2.68 3.55 3.55 0 0 1 1.13-2.64 3.86 3.86 0 0 1 2.8-1.1 3.65 3.65 0 0 1 2.7 1.1 3.52 3.52 0 0 1 1.1 2.64 3.63 3.63 0 0 1-1.1 2.68 3.74 3.74 0 0 1-2.7 1.07m19.97-11.5a7 7 0 0 0-5.32 2.25 7.75 7.75 0 0 0-2.11 5.5 7.88 7.88 0 0 0 2.11 5.53 7.09 7.09 0 0 0 5.32 2.23c2.26 0 4.01-.92 4.46-2.23v1.96h4.43V53.7h-4.43v1.96c-.44-1.3-2.2-2.23-4.46-2.23m.8 11.5a3.75 3.75 0 0 1-2.7-1.06 3.63 3.63 0 0 1-1.1-2.68 3.52 3.52 0 0 1 1.1-2.64 3.66 3.66 0 0 1 2.7-1.1 3.74 3.74 0 0 1 2.77 1.1 3.47 3.47 0 0 1 1.16 2.64 3.58 3.58 0 0 1-1.16 2.68 3.83 3.83 0 0 1-2.77 1.07m12.55 1.48c1.45 1.79 3.62 2.68 6.54 2.68 4.4 0 6.6-2 6.6-5.06 0-1.99-1.22-3.41-3.69-4.25-1.66-.6-2.91-.8-3.48-1.04-.47-.2-.62-.41-.62-.8 0-.56.53-.95 1.49-.95a4.48 4.48 0 0 1 3.15 1.1l2.1-2.91a7.12 7.12 0 0 0-5.14-1.93 6.58 6.58 0 0 0-4.13 1.27 4.14 4.14 0 0 0-1.7 3.54c0 2 1.16 3.36 3.45 4.16.45.15 1.13.36 2.02.57a7.82 7.82 0 0 1 1.82.6.82.82 0 0 1 .5.7c0 .8-.74 1.22-2.26 1.22a5.49 5.49 0 0 1-4.1-1.63z"
          data-name="Path 14922"
        />
        <path
          d="M235.06 10.87h-.83v-.45h2.16v.45h-.82v2.61h-.51z"
          data-name="Path 14923"
        />
        <path
          d="m238.23 12.54-.86-1.28v2.22h-.5v-3.06h.49l1.04 1.56 1.04-1.56h.48v3.06h-.5v-2.22l-.86 1.28z"
          data-name="Path 14924"
        />
      </g>
    </svg>
  );
};

export default IconLogo;
