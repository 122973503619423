import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import ProductList from '../components/Product/ProductList';
import { PageIntro } from '../components/Layout/PageIntro/PageIntro';

const CollectionPages = ({
  data: {
    datoCmsCollection: { title, id, metaTags, showAllProducts },
    allProducts,
    allDatoCmsProduct,
    homeCrumb,
  },
  pageContext,
}) => {
  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: title, id: id },
  ];

  const productList = showAllProducts ? allProducts : allDatoCmsProduct;

  return (
    <PageWrapper pageData={pageContext} metaTags={metaTags}>
      <PageIntro title={title} breadcrumbs={breadcrumbs} button />
      <ProductList products={productList} />
    </PageWrapper>
  );
};

export default CollectionPages;

export const query = graphql`
  query CollectionPages($locale: String!, $id: String!) {
    datoCmsCollection(locale: { eq: $locale }, originalId: { eq: $id }) {
      locale
      title
      showAllProducts
      metaTags {
        title
        description
        image {
          url
        }
      }
      id: originalId
    }
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
    allDatoCmsProduct(
      filter: {
        locale: { eq: $locale }
        collection: { originalId: { eq: $id } }
      }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        locale
        id: originalId
        title
        brand
        description
        productFeatureList {
          specReference {
            title
            titleTranslation
          }
          value
          showOnProductCard
        }
        featureImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "450"
              fit: "fill"
              fill: "solid"
              w: "680"
              q: 60
              fillColor: "00ffffff"
              auto: "format"
            }
          )
        }
      }
    }
    allProducts: allDatoCmsProduct(
      filter: { locale: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        locale
        id: originalId
        title
        description
        productFeatureList {
          specReference {
            title
            titleTranslation
          }
          value
          showOnProductCard
        }
        featureImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "450"
              fit: "fill"
              fill: "solid"
              w: "680"
              q: 60
              fillColor: "00ffffff"
              auto: "format"
            }
          )
        }
      }
    }
  }
`;
