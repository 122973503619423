import React from 'react';
import style from './page-intro.mod.scss';
import { graphql, useStaticQuery } from 'gatsby';
import Container from '../SharedStyles/Container';
import { Navigator } from '../../LanguageHelpers/Navigator';
import Button from '../../Button/Button';

export const PageIntro = ({ title, breadcrumbs, button = false }) => {
  const contactPage = useStaticQuery(graphql`
    {
      datoCmsContactPage {
        originalId
      }
    }
  `);

  return (
    <div className={style.intro}>
      <Container width="medium" pt={1}>
        <div className={style.intro__top}>
          {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
          {button && (
            <div className={style.intro__button}>
              <Button recordId={contactPage.datoCmsContactPage.originalId}>
                {'Get the best price'}
              </Button>
            </div>
          )}
        </div>
        <h1>{title}</h1>
      </Container>
    </div>
  );
};

const Breadcrumbs = ({ items }) => {
  return (
    <div className={style.intro__breadcrumbs}>
      {items.map(({ title, id }) => (
        <span className={style.intro__breadcrumb}>
          <Navigator recordId={id}>{title}</Navigator>
          <span className={style.intro__divider}>{'/'}</span>
        </span>
      ))}
    </div>
  );
};
